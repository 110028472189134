import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIconComponent } from '../../../../../../../shared/components/svg-icon/svg-icon.component';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'sfa-svg-info-icon',
  standalone: true,
  imports: [SvgIconComponent, MatTooltip],
  templateUrl: './svg-info-icon.component.html',
  styleUrl: './svg-info-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgInfoIconComponent {
  @Input() infoText: string = '';
}
